import React, { Component } from "react";
import { Pie } from "react-chartjs-2";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";
import { Layout, PortfolioNav, PortfolioHeader } from "../../../components";
import locales from "../../../constants";

class Imperial extends Component {
  render() {
    const lang = "en";
    const data = {
      labels: [
        "Display",
        "Paid Search",
        "Organic Search",
        "Social",
        "Direct",
        "Other",
        "Referral",
      ],
      datasets: [
        {
          label: "Population (millions)",
          data: [42.5, 21.2, 13.9, 8.7, 7.0, 4.8, 2.0],
          backgroundColor: [
            "#058DC7",
            "#4FB331",
            "#ED551B",
            "#EDEF00",
            "#23CBE5",
            "#63E571",
            "#FF9555",
          ],

          hoverBorderColor: "#fff",
        },
      ],
    };
    const nav = {
      initialSlideIndex: 1,
    };
    const { slug } = this.props.pageContext;
    return (
      <Layout
        header={{
          background:
            "linear-gradient( 45deg, #B9A013, #DABD15, #EDCD16, #DABD15, #DABD15 )",
          icons: "#fcda16",
          navClass: "imperial",
        }}
        seo={{
          title: "Imperial Stawowa Residence",
          headerTitle: "imperial",
          href: slug,
          lang: "en",
          ogImage: require("../../../assets/img/portfolio/imperial_top_back.png"),
        }}
        langSwitch={{
          langKey: "pl",
          langSlug: "/projekty/imperial-stawowa-residence/",
        }}
      >
        <PortfolioHeader name="imperial" height="238" />
        <section className="container-fluid imperial_section_2" id="info">
          <div className="row">
            <div className="col-md-6">
              <div className="inner">
                <h1>
                  Imperial <br /> Stawowa Residence
                </h1>
                <ul>
                  <li>Lead Generation Campaign</li>
                  <li>Case Study</li>
                </ul>
              </div>
            </div>

            <div className="col-md-6">
              <div className="inner">
                <p>
                  Imperial Capital is an investment company that comprehensively
                  carries out housing investments and development projects on
                  the Krakow housing and office markets. One of the developer's
                  residential investments is Stawowa Residence - an intimate
                  complex consisting of seven residential buildings, located in
                  Bronowice Wielkie, a district of Kraków, Poland.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="container-fluid imperial_section_3">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <h4>Client’s problems</h4>
              <p>
                The main business goal of the campaign was to sell all of the
                remaining apartments. A secondary, but also very important goal,
                was to build a positive brand image. In order to successfully
                sell apartments, it was necessary to increase brand awareness
                among those looking for an apartment to buy. The brand’s image
                on the Internet has an impact on its perception in reality,
                which directly influences sales. In October 2017 the Client came
                to us with a request to support sales with paid marketing
                campaigns.
              </p>

              <h4>Solution - A classic sales funnel</h4>
              <p>
                We meticulously analyzed the brief sent by the Client. We were
                thinking about the strategy, finding the most important key
                phrases, and presenting the benefits, and advantages of the
                investment over other similar ones that are offered on the
                market in Kraków.
              </p>
              <p>
                We focused on campaigns targeting the search network, containing
                ads with phrases related to the purchase of an apartment and
                brand phrases. In addition, we've created an
                advertising-targeted campaign that included banners and flexible
                ads. We immediately created remarketing lists, thanks to which,
                after reaching the right size of the list, we were able to
                target ads with a more precise message to users who, according
                to specific rules, reacted with the Client's website.
              </p>
              <p>
                The plan was as follows - by increasing brand awareness and
                recognition, and passing the next stages of the sales funnel -
                to reach the moment of selling out the apartments from all three
                stages of the investment.
              </p>
              <p>
                In the beginning we created a general message, informing the
                users (in a certain age group and in a specific location) about
                the existence of the investment, increasing brand awareness, and
                proclaiming the positive flow of the brand. The message was to
                arouse interest and intrigue the potential buyers. Here we used
                the GDN campaign (using static and dynamic banners [see our
                examples here{" "}
                <a href="https://when.pl/uslugi/banery-remarketingowe/">
                  https://when.pl/uslugi/banery-remarketingowe/
                </a>
                ] and flexible advertising), displayed on the Google advertising
                network. The use of dynamic banners with animated, changing
                graphics - is a great complement to static ads. We directed
                these creations to users willing to buy an apartment, interested
                in investment or mortgage. In addition, a search campaign was
                launched using expanded text ads. At this stage, users were
                looking for rather general information by entering general
                phrases related to the investment, such as the Krakow developer
                or buying an apartment from the developer. The next step on the
                purchasing path is to consider buying. Buy or not to buy this
                apartment? What apartment should I buy?
              </p>
              <p>
                We used remarketing, thanks to which we reached people who were
                already "connected" with the investment to some extent. Users
                searched the site, spent some time on it, downloaded the PDF
                with the apartment overview, consulted an advisor, and maybe
                even visited the investment area. An additional criterion we
                took into account was the time spent on the website and the
                number of viewed subpages. These indicators showed us the level
                of actual user involvement.
              </p>
              <p>
                Remarketing in the real estate development industry works
                extremely well, as we know, the apartment purchasing process
                does not take several days. It usually requires a lot of thought
                processing, arrangements, formalities, etc., so reaching such a
                selected user with a subtle and personalized message (e.g.
                informing about the start of the sale of the third stage, or
                promotion of an apartment from stage I for it) can speed up his
                or her decision to buy an apartment.
              </p>
              <p>
                Throughout the process of supporting the Client with Google Ads
                campaign, we have constantly optimized it, eliminating
                unsuccessful searches (excluding key phrases), updated
                information on a regular basis, changed advertising creations,
                conducted numerous A / B tests, and made all this in order to
                reliably reach the users most interested in buying the apartment
                with our message.
              </p>

              <h4>Results of our work</h4>
              <p>
                The result of our work was building a strong image of the brand,
                arousing the interest of the potential buyers, and supporting
                the sale of apartments in the Stawowa Residence investment with
                a Google Ads campaign.
              </p>
              <p>
                Below we present the channels that brought users to the
                imperialstawowa.pl website. Google Ads is leading the way -
                mainly with an advertising network campaign (flexible and banner
                advertising), representing over 42% of the generated traffic. A
                search campaign accounts for over 21% of all traffic.
              </p>

              <div className="canvas_wrapper">
                <Pie data={data} />
              </div>
              <TrackVisibility once offset={300}>
                {({ isVisible }) => (
                  <>
                    <h4>Key success factors</h4>
                    <ul>
                      <li>
                        Our advertisements were displayed{" "}
                        <CountUp
                          start={0}
                          duration={3}
                          decimals={1}
                          end={isVisible ? 27.2 : 0}
                        />{" "}
                        mln times
                      </li>
                      <li>
                        Our advertisements were clicked by users over{" "}
                        <CountUp
                          start={0}
                          duration={3}
                          decimals={1}
                          end={isVisible ? 81.1 : 0}
                        />{" "}
                        times, resulting in actually users visiting the website
                        of the investment.
                      </li>
                      <li>
                        The average conversion cost amounted to{" "}
                        <CountUp
                          start={0}
                          duration={3}
                          decimals={2}
                          end={isVisible ? 15.98 : 0}
                        />{" "}
                        PLN
                      </li>
                      <li>
                        The average PPC (Pay Per Click) amounted to{" "}
                        <CountUp
                          start={0}
                          duration={3}
                          decimals={2}
                          end={isVisible ? 0.72 : 0}
                        />{" "}
                        PLN
                      </li>
                    </ul>
                    <p>
                      During the whole period of our campaign we managed to
                      achieve, among others:
                    </p>
                    <ul>
                      <li>
                        <CountUp
                          start={0}
                          duration={3}
                          end={isVisible ? 1966 : 0}
                        />{" "}
                        clicks on the “Contact” page
                      </li>
                      <li>
                        <CountUp
                          start={0}
                          duration={3}
                          end={isVisible ? 1772 : 0}
                        />{" "}
                        downloads of the PDF files with apartments overview
                      </li>
                      <li>
                        <CountUp
                          start={0}
                          duration={3}
                          end={isVisible ? 49 : 0}
                        />{" "}
                        sent contact requests via the contact form on the
                        website
                      </li>
                    </ul>
                    <p>
                      At present{" "}
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 112 : 0}
                      />{" "}
                      apartments have been sold{" "}
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 104 : 0}
                      />
                      ,therefore{" "}
                      <CountUp
                        start={0}
                        duration={3}
                        decimals={1}
                        end={isVisible ? 92.8 : 0}
                      />{" "}
                      of the investment from all three stages was sold. Our goal
                      is to close the sale at{" "}
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 100 : 0}
                      />
                      %.
                    </p>
                  </>
                )}
              </TrackVisibility>
            </div>
          </div>
        </section>
        <PortfolioNav data={nav} locales={locales[lang]} />
      </Layout>
    );
  }
}

export default Imperial;
